<template>
  <div>
    <div class="air__utils__heading">
      <h5>Perfil de {{user.name}}</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <div class="row justify-content-center">
      <!--  General Info-->
      <div class="col-xl-3 col-lg-4 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-wrap flex-column align-items-center">
              <div class="kit__utils__avatar kit__utils__avatar--size64 mb-3">
                <img :src="user.avatar" :alt="user.name" />
              </div>
              <div class="text-center">
                <div class="text-dark font-weight-bold font-size-18">{{user.name}}</div>
                <div class="text-uppercase font-size-12 mb-3">{{ user.role }}</div>
                <div class="font-size-12 mb-3">{{ user.email }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  General Info-->
      <!--      Formularios -->
      <div class="col-xl-9 col-lg-8 col-md-12">
        <div class="card pt-5">
          <div class="card-header card-header-flex flex-column">
            <a-tabs default-active-key="1" animated size="small" type="card" @change="callback">
              <a-tab-pane key="1" >
                <span slot="tab">
                  <a-icon type="user" />
                  Información Personal
                </span>
                <div class="card-body my-3">
                  <!--      Info Personal -->
                  <a-form-model
                    class="mb-1"
                    ref="rule-General"
                    :model="generalInfoForm"
                    :rules="generalInfoFormRules"
                    layout="vertical"
                    :wrapper-col="{ span: 24}"
                  >
                    <a-form-model-item ref="name"  prop="name" label="Nombre">
                      <a-input v-model.trim="generalInfoForm.name" size="large" :placeholder="user.name" />
                    </a-form-model-item>
                    <a-form-model-item ref="email"  prop="email" label="Email">
                      <a-input v-model.trim="user.email" size="large" :placeholder="user.email" disabled/>
                    </a-form-model-item>

                    <my-spinner :load="loading"/>

                    <div class="form-actions mt-0" v-show="!loading">
                      <a-button type="primary" class="mr-3" @click="onSubmit"><b-icon icon="archive-fill"></b-icon>Guardar</a-button>
                    </div>
                  </a-form-model>
                  <!--      Info Personal -->
                </div>
              </a-tab-pane>
              <a-tab-pane key="2" >
                <span slot="tab">
                  <a-icon type="lock" />
                  Seguridad
                </span>
                <!--      Seguridad -->
                <div class="card-body my-3">
                  <a-form-model
                    class="mb-1"
                    ref="rule-Security"
                    :model="securityForm"
                    :rules="securityFormRules"
                    layout="vertical"
                    :wrapper-col="{ span: 24}"
                  >
                    <a-form-model-item ref="current_password"  prop="current_password" label="Contraseña Actual">
                      <a-input v-model.trim="securityForm.current_password" type="password" size="large" placeholder="Contraseña Actual" />
                    </a-form-model-item>
                    <a-form-model-item ref="password"  prop="password" label="Contraseña">
                      <a-input v-model.trim="securityForm.password" type="password" size="large" placeholder="Nueva Contraseña" />
                    </a-form-model-item>
                    <a-form-model-item ref="password_confirmation" label="Confirme su Contraseña"  prop="password_confirmation" >
                      <a-input v-model.trim="securityForm.password_confirmation" type="password" size="large" placeholder="Confirme su Contraseña" />
                    </a-form-model-item>
                    <my-spinner :load="securityFormLoad"/>

                    <div class="form-actions mt-0" v-show="!securityFormLoad">
                      <a-button type="primary" class="mr-3" @click="savePassword()"><b-icon icon="arrow-counterclockwise"></b-icon>Actualizar</a-button>
                    </div>
                  </a-form-model>
                </div>
                <!--      Seguridad -->
              </a-tab-pane>
              <a-tab-pane key="3" >
                <span slot="tab">
                  <a-icon type="security-scan" />
                  Roles y Permisos
                </span>
                <div class="card-body my-3">
                  <!--      Roles Y Permisos -->
                  <user-roles-and-permissions :emailUser="user.email"/>
                  <!--      Roles Y Permisos -->
                </div>
              </a-tab-pane>
              <a-tab-pane key="4" >
                <span slot="tab">
                  <a-icon type="solution" />
                  Información de Empleado
                </span>
                <div class="card-body">
                  <!--  EmployeeSettings -->
                  <employee-settings-user :emailUser="user.email"/>
                  <!--  EmployeeSettings -->
                </div>
              </a-tab-pane>
            </a-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/axios/index'
import userRolesAndPermissions from '../components/userRolesAndPermissions'
import employeeSettingsUser from '../components/employeeSettingsUser'
// import KitList19 from './widgets/Lists/19/index'
export default {
  components: {
    userRolesAndPermissions,
    employeeSettingsUser,
    // KitList19,
  },
  data() {
    return {
      activeKey: '1',
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Perfil de Usuario',
          aIcon: '',
        },
      ],
      generalInfoForm: {
        name: '',
        file: '',
      },
      generalInfoFormRules: {
        name: [
          { required: true, message: 'El Nombre es obligatorio.', trigger: 'blur' },
          { type: 'string', message: 'El Nombre no es válido.', trigger: 'blur' },
        ],
      },
      securityForm: {
        current_password: '',
        password: '',
        password_confirmation: '',
      },
      securityFormRules: {
        current_password: [
          { required: true, message: 'Esta campo es obligatorio.', trigger: 'blur' },
          { type: 'string', message: 'Esta campono es válido.', trigger: 'blur' },
          { min: 8, message: 'La contraseña debe contener al menos 8 caracteres', trigger: 'blur' },
          {
            validator: () => {
              if (this.securityForm.current_password !== this.securityForm.password && this.securityForm.current_password !== this.securityForm.password_confirmation) {
                return true
              } else {
                return false
              }
            },
            message: 'La Nueva Contraseña debe ser distinta a la anterior.',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: 'Esta campo es obligatorio.', trigger: 'blur' },
          { type: 'string', message: 'Esta campo no es válido.', trigger: 'blur' },
          { min: 8, message: 'La contraseña debe contener al menos 8 caracteres', trigger: 'blur' },
        ],
        password_confirmation: [
          { required: true, message: 'Esta campo es obligatorio.', trigger: 'blur' },
          { type: 'string', message: 'Esta campo no es válido.', trigger: 'blur' },
          { min: 8, message: 'La contraseña debe contener al menos 8 caracteres', trigger: 'blur' },
          {
            validator: () => {
              if (this.securityForm.password === this.securityForm.password_confirmation) {
                return true
              } else {
                return false
              }
            },
            message: 'Las Contraseñas no coinciden.',
            trigger: 'blur',
          },
        ],
      },
      securityFormLoad: false,
    }
  },
  computed: {
    ...mapState(['user']),
    user() {
      return this.$store.state.user
    },
    loading() {
      return this.$store.state.user.loading
    },
  },
  methods: {
    onSubmit() {
      this.$refs['rule-General'].validate(valid => {
        if (valid) {
          this.$store.dispatch('user/UPDATE_PROFILE', {
            payload: {
              name: this.generalInfoForm.name,
              email: this.user.email,
            },
          })
        } else {
          return false
        }
      })
    },
    savePassword() {
      console.log('Running')
      this.$refs['rule-Security'].validate(valid => {
        if (valid) {
          api.put('/user/password', {
            current_password: this.securityForm.current_password,
            password: this.securityForm.password,
            password_confirmation: this.securityForm.password_confirmation,
          }).then(res => {
            console.log(res)
          }).catch(error => {
            console.log(error)
          })
        } else {
          return false
        }
      })
    },
    callback(key) {
      if (key === '3') {
        // Listar roles y permisos
      } else if (key === '4') {
        // Listar info de Employee_settings
      }
    },
  },
}
</script>

<style lang="scss" module>
@import '@/mixins.scss';
.btnWithAddon {
  overflow: hidden;
  position: relative;
  padding-left: rem(50) !important;
  border: none;

  .btnAddon {
    position: absolute;
    z-index: 1;
    top: -1px;
    left: -1px;
    bottom: -1px;
    background-color: rgba($white, 0.2);
    width: rem(40);
  }

  .btnAddonIcon {
    font-size: rem(16);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.card-container {
  background: #f5f5f5;
  overflow: hidden;
  padding: 24px;
}
.card-container > .ant-tabs-card > .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
</style>
