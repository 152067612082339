<template>
  <b-container fluid>
    <a-form-model layout="horizontal" :model="roleForm" :rules="{}" ref="rule-Role">
      <h5 class="text-secondary text-left font-weight-bold mt-3">Asignación de Roles</h5>
      <hr class="bg-primary">
      <a-row align="middle" justify="center" class="mb-4">
        <a-col :span="24"  class="pr-2 border-right border-w-3 border-primary">
          <h6 class="text-secondary text-left text-capitalize mt-1">Roles</h6>
          <hr class="bg-primary">
          <a-form-model-item>
            <a-checkbox-group v-model="roleForm.roles">
              <a-row>
                <a-col :span="24" v-for="option in Roles" :key="option.name" class="my-1">
                  <a-checkbox :value="option.name" :disabled="!userHasPermission">{{option.display_name}}</a-checkbox>
                  <br>
                  <div class="text-justify">
                    <small><strong>Cuenta con los permisos para: </strong></small>
                    <small v-for="permission in option.permissions" :key="permission.name">
                      <b-icon icon="key-fill"></b-icon> {{ permission.display_name }},
                    </small>
                  </div>
                  <hr class="bg-primary">
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-form-model-item>
        </a-col>

        <div class="form-actions mt-0" v-show="userHasPermission">
          <a-row>
            <a-col span="4" offset="10">
              <responsive-button v-show="!roleLoading" block variant="primary" icon="arrow-counterclockwise" class="mr-3" size="md" responsive="md" @ClickEvent="setRoles" pill text="Actualizar Roles"/>
              <my-spinner :load="roleLoading"></my-spinner>
            </a-col>
          </a-row>
        </div>

      </a-row>
    </a-form-model>
    <hr class="bg-primary my-5">
    <a-form-model layout="horizontal" :model="PermissionForm" :rules="{}" ref="rule-Permission">
      <h5 class="text-secondary text-left font-weight-bold mt-3">Asignación de Permisos Adicionales</h5>
      <hr class="bg-primary">
      <a-row align="middle" justify="center" class="mb-4">
        <a-col :span="24" class="px-2 border-right border-w-3 border-primary">
          <h6 class="text-secondary text-left text-capitalize mt-1">Permisos Adicionales</h6>
          <hr class="bg-primary">
          <a-form-model-item>
            <a-checkbox-group v-model="PermissionForm.permissions">
              <a-row>
                <a-col :sm="{ span: 24, }" :md="{ span:12}" v-for="option in Permissions" :key="option.name" class="my-1">
                  <a-checkbox :value="option.name" :disabled="!userHasPermission">
                    <small>{{option.display_name}}</small>
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-form-model-item>
        </a-col>

        <div class="form-actions mt-0" v-show="userHasPermission">
          <a-row>
            <a-col span="4" offset="10">
              <responsive-button v-show="!roleLoading" block variant="primary" icon="arrow-counterclockwise" class="mr-3" size="md" responsive="md" @ClickEvent="setPermissions" pill text="Actualizar Permisos"/>
              <my-spinner :load="permissionsLoading"></my-spinner>
            </a-col>
          </a-row>
        </div>

        <!-- <div class="form-actions mt-0" v-show="userHasPermission">
          <a-button type="primary" class="mr-3" @click="setPermissions()" :loading="permissionsLoading">Actualizar Permisos</a-button>
        </div> -->

      </a-row>
    </a-form-model>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/services/axios/index'
import { Modal } from 'ant-design-vue'
export default {
  name: 'userRolesAndPermissions',
  props: {
    emailUser: {
      default: '',
      type: [String],
      required: true,
    },
  },
  computed: {
    ...mapState(['user']),
    user() {
      return this.$store.state.user
    },
    loading() {
      return this.$store.state.user.loading
    },
    userHasPermission() {
      return (this.user.allPermissionsNames.includes('admin_users'))
    },
  },
  data() {
    return {
      roleLoading: false,
      roleForm: {
        roles: [],
      },
      permissionsLoading: false,
      PermissionForm: {
        permissions: [],
      },
      // Catalogs
      Roles: [],
      Permissions: [],
    }
  },
  methods: {
    getUserInfo() {
      api.get(`/user/${this.emailUser}`)
        .then(response => {
          // Get the role and permission info
          const user = response.data.user
          const roleNames = user.allRolesNames
          const permissionNames = user.directPermissionsNames
          // set the roles and permission in the form
          this.roleForm.roles = roleNames
          this.PermissionForm.permissions = permissionNames
        })
    },
    getRoles() {
      api.get('/roles').then(res => {
        this.Roles = res.data.roles
      })
    },
    getPermissions() {
      api.get('/permissions').then(res => {
        this.Permissions = res.data.permissions
      })
    },
    setRoles() {
      const request = {
        email: this.emailUser,
        roles: this.roleForm.roles,
      }
      this.roleLoading = true
      api.put('/setRoles', request)
        .then(response => {
          // Get the role  info
          const user = response.data.user
          const roleNames = user.allRolesNames
          Modal.success({
            title: '¡Actualizado!',
            content: 'La asignación de los roles se ha actualizado exitosamente',
            zIndex: 3000,
          })
          // set the roles  in the form
          this.roleForm.roles = roleNames
          this.roleLoading = false
        })
        .catch(error => {
          console.log(error)
          this.roleLoading = false
        })
    },
    setPermissions() {
      const request = {
        email: this.emailUser,
        permissions: this.PermissionForm.permissions,
      }
      this.permissionsLoading = true
      api.put('/setPermissions', request)
        .then(response => {
          // Get the role  info
          const user = response.data.user
          const permissionNames = user.directPermissionsNames
          Modal.success({
            title: '¡Actualizado!',
            content: 'La asignación de permisos se ha actualizado exitosamente',
            zIndex: 3000,
          })
          // set the roles  in the form
          this.PermissionForm.permissions = permissionNames
          this.permissionsLoading = false
        })
        .catch(error => {
          console.log(error)
          this.permissionsLoading = false
        })
    },
  },
  mounted() {
    this.getRoles()
    this.getPermissions()
    this.getUserInfo()
  },
}
</script>

<style scoped>
.border-w-3 {
  border-width:3px !important;
}
.border-w-5 {
  border-width:5px !important;
}
</style>
