<template>
  <b-container fluid>
<!-- TEST -->

<!-- TEST -->
    <a-form-model layout="horizontal" :model="SettingForm" :rules="SettingFormRules" ref="rule-Setting">
      <a-row>
<!--        <a-col :sm="{span:24}" :lg="{span:8}">-->
          <a-form-model-item ref="area_id" label="Área" prop="area_id" >
            <a-select v-model="SettingForm.area_id"
                      :disabled="!userHasPermission"
                      placeholder="Seleccionar Área"
                      show-search
                      :filter-option="filterOption"
            >
              <a-select-option  v-for="area in AreasCatalog" :key="area.jsonApi.id" :value="area.jsonApi.id" >
                {{area.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
<!--        </a-col>-->
<!--        <a-col :sm="{span:24}" :lg="{span:8}">-->
          <a-form-model-item ref="work_center_id" label="Planta" prop="work_center_id" >
            <a-select v-model="SettingForm.work_center_id"
                      :disabled="!userHasPermission"
                      placeholder="Seleccionar Planta"
                      show-search
                      :filter-option="filterOption"
            >
              <a-select-option  v-for="plant in WorkCentersCatalog" :key="plant.jsonApi.id" :value="plant.jsonApi.id" >
                {{plant.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
<!--        </a-col>-->
<!--        <a-col :sm="{span:24}" :lg="{span:8}">-->
          <a-form-model-item ref="employee_position_id" label="Puesto/Posición" prop="employee_position_id" >
            <a-select v-model="SettingForm.employee_position_id"
                      :disabled="!userHasPermission"
                      placeholder="Seleccionar Puesto/Posición"
                      show-search
                      :filter-option="filterOption"
            >
              <a-select-option  v-for="item in EmployeePositionsCatalog" :key="item.jsonApi.id" :value="item.jsonApi.id" >
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
<!--        </a-col>-->
        <div class="form-actions mt-0" v-show="userHasPermission">
          <a-row>
            <a-col span="4" offset="10">
              <responsive-button v-show="!settingsLoading" block variant="primary" icon="archive-fill" class="mr-3" size="md" responsive="md" @ClickEvent="setSettings" pill text="Guardar"/>
              <my-spinner :load="settingsLoading"></my-spinner>
            </a-col>
          </a-row>
        </div>

      </a-row>
    </a-form-model>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/services/axios/index'
import { objectArray } from '@/helpers/jsonApiTransformer'
import { notification } from 'ant-design-vue'
export default {
  name: 'employeeSettingsUser',
  props: {
    emailUser: {
      default: '',
      type: [String],
      required: true,
    },
  },
  computed: {
    ...mapState(['user']),
    user() {
      return this.$store.state.user
    },
    loading() {
      return this.$store.state.user.loading
    },
    userHasPermission() {
      return (this.user.allPermissionsNames.includes('admin_users'))
    },
  },
  data() {
    return {
      // Form
      current_user: {},
      settingsLoading: false,
      SettingForm: {
        area_id: undefined,
        work_center_id: undefined,
        employee_position_id: undefined,
      },
      SettingFormRules: {
        area_id: [{ required: true, message: 'El campo Área es obligatorio.', trigger: 'blur' }],
        work_center_id: [{ required: true, message: 'El campo Área es obligatorio.', trigger: 'blur' }],
        employee_position_id: [{ required: true, message: 'El campo Puesto/Posición es obligatorio.', trigger: 'blur' }],
      },
      // Relaciones
      relationships: {
        areas: {
          data: { id: 0, type: 'areas' },
        },
        workCenters: {
          data: { id: 0, type: 'workCenters' },
        },
        employeePositions: {
          data: { id: 0, type: 'employeePositions' },
        },
        users: {
          data: { id: 0, type: 'users' },
        },
      },
      // SettingID
      settingID: '',
      // Form
      // Combos
      AreasCatalog: [],
      EmployeePositionsCatalog: [],
      WorkCentersCatalog: [],
      // Combos
    }
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    resetRelathionships() {
      this.relationships = {
        areas: {
          data: { id: 0, type: 'areas' },
        },
        workCenters: {
          data: { id: 0, type: 'workCenters' },
        },
        employeePositions: {
          data: { id: 0, type: 'employeePositions' },
        },
        users: {
          data: { id: 0, type: 'users' },
        },
      }
    },
    getUserInfo() {
      api.get(`/user/${this.emailUser}`)
        .then(response => {
          // Get the role and permission info
          const user = response.data.user
          const settings = user.employeeSettings
          this.current_user = user
          if (settings !== null) {
            this.SettingForm.employee_position_id = settings.employee_positions.code
            this.SettingForm.area_id = settings.areas.code
            this.SettingForm.work_center_id = settings.work_centers.code
            this.settingID = settings.code
          }
        })
    },
    setSettings() {
      this.$refs['rule-Setting'].validate(valid => {
        if (valid) {
          let formObject = {}
          let action = 'post'
          // build the relationships with the form data
          this.relationships.areas.data.id = this.SettingForm.area_id
          this.relationships.workCenters.data.id = this.SettingForm.work_center_id
          this.relationships.employeePositions.data.id = this.SettingForm.employee_position_id
          this.relationships.users.data.id = `${this.current_user.id}`
          if (this.settingID === '') {
            // Store Object
            formObject = {
              jsonApi: {
                type: 'employeeSettings',
                relationships: this.relationships,
              },
            }
            // Store Object
          } else {
            // Update Object
            action = 'patch'
            formObject = {
              jsonApi: {
                type: 'employeeSettings',
                id: this.settingID,
                relationships: this.relationships,
              },
            }
            // Update Object
          }
          // Envio del Objecto
          this.resetRelathionships()
          this.settingsLoading = true
          this.$store.dispatch(`jv/${action}`, [formObject, {}])
            .then((data) => {
              notification.success({
                message: 'Registro actualizado exitosamente',
              })
              this.getUserInfo()
              this.settingsLoading = false
            })
            .catch(() => {
              this.settingsLoading = false
            })
          // Envio del Objecto
        } else {
          return false
        }
      })
    },
    async getAreas() {
      await this.$store.dispatch('jv/get', ['areas', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.AreasCatalog = objectArray(tableData)
        })
    },
    async getEmployeePositions() {
      await this.$store.dispatch('jv/get', ['employeePositions', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.EmployeePositionsCatalog = objectArray(tableData)
        })
    },
    async getWorkCenters() {
      await this.$store.dispatch('jv/get', ['workCenters', {
        params: {
          sort: 'name',
        },
      }])
        .then(response => {
          const tableData = response
          this.WorkCentersCatalog = objectArray(tableData)
        })
    },
  },
  async mounted() {
    await this.getAreas()
    await this.getEmployeePositions()
    await this.getWorkCenters()
    this.getUserInfo()
  },
}
</script>

<style scoped>

</style>
